import styled from "@emotion/styled"
import React from "react"
import colors from "../../styles/colors"
import { MobileMediaQuery } from "../../styles/constants"
import { ContentfulImageProps } from "../../types/contentful"
import AspectRatio from "../aspectRatio"
import { ContentfulImage } from "../ContentfulImage"
import Link from "../link"
import { typographyStyles } from "../typography"

const Tiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;

  ${MobileMediaQuery} {
    margin-left: 0;
    margin-right: 0;
  }
`

const TileLink = styled(Link)`
  width: calc(33% - 32px);
  margin: 16px;
  border-radius: 16px;
  overflow: hidden;
  transition: box-shadow 200ms ease-in-out, transform 120ms ease-out;

  /* Safari fix */
  img {
    border-radius: 16px;
  }

  ${MobileMediaQuery} {
    width: 100%;
    margin: 0;
    margin-bottom: 16px;
  }

  :hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.01);
  }
`
const TileName = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
  padding: 10px 20px;
  background-color: ${colors.white};
  color: ${colors.darkgrey};
  border-radius: 100px;
  ${typographyStyles.bodySmall}
`

export interface Tile {
  slug: string
  thumbnailImage: ContentfulImageProps
  useCaseName: string
}
export const TestDriveTiles = ({
  tiles,
  ...props
}: { tiles: Tile[] } & React.HTMLAttributes<HTMLDivElement>) => (
  <Tiles {...props}>
    {tiles.map((tile, index) => (
      <TileLink key={index} to={tile.slug}>
        <AspectRatio ratio={270 / 380}>
          <div>
            <ContentfulImage image={tile.thumbnailImage} />
            <TileName>{tile.useCaseName}</TileName>
          </div>
        </AspectRatio>
      </TileLink>
    ))}
  </Tiles>
)
