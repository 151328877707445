import styled from "@emotion/styled"
import React from "react"

import { MobileMediaQuery } from "../../../styles/constants"
import Card from "../../card"
import Link from "../../link"
import colors, { hoverColors } from "../../../styles/colors"
import Typography, { typographyStyles } from "../../typography"
import { trackEvent } from "../../../utils/trackEvent"
import CardTicketFlow from "./cardTicketFlow"

const Description = styled.div`
  margin-top: 3rem;
  max-width: 740px;

  ${MobileMediaQuery} {
    margin-top: 2rem;
  }
`

const Pitch = styled.div`
  ${typographyStyles.h6}
  max-width: 800px;
  line-height: 34px;

  ${MobileMediaQuery} {
    ${typographyStyles.bodySmall}
  }
`

const HoverLink = styled(Link)`
  display: inline-block;
  border-radius: 4px;
  padding: 0.2rem 0.3rem;
  color: ${colors.white};
  margin: 0 3px;
  background-color: ${p => colors[p.variant || "primary"]};
  text-decoration: none;

  &:hover {
    background-color: ${p => hoverColors[p.variant || "primary"]};
  }
`

const CardNoChitChat = () => (
  <>
    <Card variant="white" center style={{ overflow: "visible" }}>
      <Typography variant="h1" style={{ color: colors.dark }}>
        Less chit-chat,
        <br /> More problem-solving
      </Typography>
      <Description>
        <Typography variant="bodyLarge" style={{ color: colors.dark }}>
          Chatbots are built to handle simple requests and promote leisurely
          conversations. Chit-chat. That’s not us. We help you solve real
          problems—like making your customers’ products work again if they
          suddenly stop.
        </Typography>
      </Description>
      <Typography
        variant="h1"
        style={{ marginTop: "4rem", color: colors.primary }}
      >
        We’re not a chatbot
      </Typography>
      <Description>
        <Typography variant="bodyLarge" style={{ color: colors.primary }}>
          The core of Mavenoid is the diagnosis engine. It uses an AI that works
          a lot like the human brain when it solves problems. The engine makes
          Mavenoid unique. We’re the first commercially available platform that
          enables you to automate troubleshooting of advanced technical
          problems. Not just the simple stuff.
        </Typography>
      </Description>
    </Card>
    <CardTicketFlow style={{ marginTop: "6rem" }} />
    <Card variant="white" center noMinHeight>
      <Pitch>
        Mavenoid’s{" "}
        <HoverLink
          variant="lightpurple"
          to="/product/self-service/"
          onClick={() => {
            trackEvent({
              category: "view_item",
              label: "homepage no chit chat self service",
              siteSection: "homepage_no_chit_chat",
            })
          }}
        >
          self-service
        </HoverLink>{" "}
        solves repetitive requests before they reach your human support team.
        Humans will deal only with the tickets that really require their
        expertise, through Mavenoid{" "}
        <HoverLink
          to="/product/live-support/"
          onClick={() => {
            trackEvent({
              category: "view_item",
              label: "homepage no chit chat live support",
              siteSection: "homepage_no_chit_chat",
            })
          }}
        >
          live support
        </HoverLink>
        .
      </Pitch>
    </Card>
  </>
)

export default CardNoChitChat
