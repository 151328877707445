import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { QuoteWidget } from "../widgets/QuoteWidget"

export const CardMathijs = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulQuoteWidget(contentful_id: { eq: "7e45PZAS7LLXVxKw3KhPRk" }) {
        ...QuoteWidget
      }
    }
  `)

  console.log(data)

  return <QuoteWidget widget={data.contentfulQuoteWidget} />
}
