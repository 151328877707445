// @ts-check
import React from "react"
import styled from "@emotion/styled"

import { typographyComponents } from "./typography"
import Checkmark from "./icons/checkmark"
import { MobileMediaQuery } from "../styles/constants"

export const List = styled(typographyComponents.bodyLarge.withComponent("ul"))`
  padding: 0;
  margin-bottom: 0;
`

const ListItemWrapper = styled.li`
  display: flex;
  margin-bottom: 10px;

  svg {
    position: relative;
    flex-shrink: 0;
    top: 4px;
    margin-right: 10px;
    width: 24px;
    height: 24px;

    ${MobileMediaQuery} {
      top: -1px;
    }
  }
`

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.icon]
 * @param {React.ReactNode} [props.children]
 */
export const ListItem = ({ icon = <Checkmark />, children, ...props }) => (
  <ListItemWrapper {...props}>
    {icon}
    {children}
  </ListItemWrapper>
)
