// @ts-check
import styled from "@emotion/styled"
import colors from "../styles/colors"

const CardSeparator = styled.hr`
  display: block;
  max-width: 1040px;
  margin: 0 auto 4px;
  height: 1px;
  border: none;
  background-color: ${(
    /** @type {{variant: import("../styles/colors").TColor}} */ p
  ) => colors[p.variant]};

  @media (max-width: 768px) {
    margin: 0 20px;
  }
`

export default CardSeparator
