// @ts-check
import React from "react"

const ZigZagMobile = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 375 451"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-26.5856-59.6601l83.5719 90.0136-31.1191-81.9271 84.7918-55.2734L242.837 23.4981 273.56-86.1962 509.028 92.7584l-62.683 82.4786-114.131-86.7392-37.009 132.1352-88.142-86.92 41.055 108.086-86.382 53.635-103.9286-111.94-5.4942 68.384-103.2622-8.296 24.3634-303.2421z"
    />
  </svg>
)

export default ZigZagMobile
