import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image/withIEPolyfill"
import colors from "../styles/colors"
import Typography, { typographyStyles } from "./typography"
import { DesktopMediaQuery, MobileMediaQuery } from "../styles/constants"
import { useIsMobile } from "../hooks/useIsMobile"
import Button from "./button"
import Link from "./link"
import BigArrow from "./icons/bigArrow"

const RoundImage = styled(Img)`
  border-radius: 160px;
  margin-right: 50px;
  margin-left: 50px;

  /* Safari fix */
  img {
    border-radius: 160px;
  }

  ${MobileMediaQuery} {
    order: 3;
    margin: 2rem 0;
    height: 100px !important;
    width: 100px !important;
  }
`

const Image = ({ imageData }) => {
  if (!imageData) return null
  return (
    <RoundImage
      alt="Jan Ackalin"
      fixed={imageData.file.childImageSharp.fixed}
      backgroundColor="#4f4f4f" // hardcoded color, related to the picture
    />
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;

  ${MobileMediaQuery} {
    flex-direction: column;
    padding: 0 20px;
    align-items: center;
    width: 100%;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  ${MobileMediaQuery} {
    order: 4;
    align-items: center;
  }
`
const Description = styled(Typography)`
  margin-left: 2rem;
  flex: 2;
  max-width: 600px;

  ${MobileMediaQuery} {
    text-align: center;
    margin-left: 0;
    margin-top: 2rem;
    order: 1;
  }
`

const Info = styled.div`
  ${typographyStyles.bodyLarge}
  display: flex;
  flex-direction: column;
  line-height: 20px;
  ${MobileMediaQuery} {
    text-align: center;
  }
`

const SubItem = styled.span`
  color: ${colors.darkgrey};
  margin-top: 0.3rem;
`

const ReadMoreButton = styled(Button)`
  background-color: transparent;
  border-radius: 1000px;
  border: 2px solid ${colors.darkgrey};
  color: ${colors.darkgrey};
  text-transform: none;
  margin-top: 2rem;
  padding: 1rem;

  ${DesktopMediaQuery} {
    max-width: 280px;
  }
  &:hover {
    background-color: #666;
    border-color: #666;
    color: ${colors.white};
  }
`

const LogoWrapper = styled.div`
  ${DesktopMediaQuery} {
    margin-bottom: 0.2rem;
  }
`

const ReadMoreButtonWithArrow = ({ children, ...props }) => {
  return (
    <ReadMoreButton {...props}>
      <BigArrow
        style={{ minWidth: "18px", width: "18px", marginRight: "0.5rem" }}
      />
      {children}
    </ReadMoreButton>
  )
}

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {string} props.workTitle
 * @param {string} props.company
 * @param {string} props.description
 */
export const Testimonial = ({
  name,
  workTitle,
  department = null,
  company,
  profilePictureData,
  description,
  logo,
  linkTo = null,
  onClick,
}) => {
  const isMobile = useIsMobile()
  return (
    <Wrapper>
      {isMobile && logo}
      <InfoWrapper>
        <Info>
          <span style={{ color: colors.primary }}>{name}</span>
          <SubItem>{workTitle}</SubItem>
          {department && <SubItem>{department}</SubItem>}
          <SubItem>{company}</SubItem>
        </Info>
        {!isMobile && <LogoWrapper>{logo}</LogoWrapper>}
        {linkTo && isMobile && (
          <ReadMoreButtonWithArrow as={Link} to={linkTo} onClick={onClick}>
            Explore the customer story
          </ReadMoreButtonWithArrow>
        )}
      </InfoWrapper>
      <Image imageData={profilePictureData} />
      <Description variant="h4" style={{ color: colors.darkgrey }}>
        {description}
        {linkTo && !isMobile && (
          <ReadMoreButtonWithArrow as={Link} to={linkTo} onClick={onClick}>
            Explore the customer story
          </ReadMoreButtonWithArrow>
        )}
      </Description>
    </Wrapper>
  )
}
