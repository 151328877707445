// @ts-check
import React from "react"

const Checkmark = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg fill="currentColor" viewBox="0 0 25 28" {...props}>
    <path d="M21.98.35l-5.08 7.3A10.88 10.88 0 0 0 0 16.67c0 5.98 4.88 10.86 10.86 10.86 5.98 0 10.86-4.88 10.86-10.86a10.8 10.8 0 0 0-2.71-7.15l5.27-7.56-2.3-1.6zM10.86 8.61c1.65 0 3.17.49 4.44 1.33L11 16.12l-1.83-4.25-2.57 1.1 3.81 8.89 6.95-9.97a8.04 8.04 0 0 1-6.5 12.84 8.04 8.04 0 0 1-8.05-8.06c0-4.47 3.6-8.06 8.06-8.06z" />
  </svg>
)

export default Checkmark
