import React from "react"
import styled from "@emotion/styled"

import { CardWrapper } from "../../card"
import ThickArrow from "../../icons/thickArrow"
import { List, ListItem } from "../../list"
import Typography, { typographyStyles } from "../../typography"
import { BigPillButton } from "../../button"
import colors from "../../../styles/colors"
import {
  BORDER_RADIUS_LARGE,
  DesktopMediaQuery,
  MobileMediaQuery,
} from "../../../styles/constants"
import {
  ProductAssistant,
  ProductAssistantBrowser,
} from "../../illustrations/productAssistant"
import Link from "../../link"
import { trackEvent } from "../../../utils/trackEvent"

const Card = styled(CardWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1400px;
  border-radius: ${BORDER_RADIUS_LARGE}px;
  ${DesktopMediaQuery} {
    height: 750px;
  }

  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const Button = styled(BigPillButton)`
  margin-top: 4rem;
  width: 330px;
  padding: 2rem 5rem;
  ${MobileMediaQuery} {
    margin: 4rem auto;
  }
`

const Title = styled(Typography)`
  ${DesktopMediaQuery} {
    max-width: 380px;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  padding: 5rem 5rem 5rem 3rem;

  ${MobileMediaQuery} {
    padding: 2.4rem;
  }
`

const ListItemArrow = styled(ListItem)`
  ${MobileMediaQuery} {
    ${typographyStyles.bodySmall}
  }
  svg {
    ${MobileMediaQuery} {
      top: 2px;
    }
  }
`

const NewSpan = styled.div`
  margin-top: 20px;
  display: flex;
  ${MobileMediaQuery} {
    ${typographyStyles.bodySmall}
  }
`

const ImageAbsoluteWrapper = styled.div`
  flex: 1;
  position: relative;

  ${DesktopMediaQuery} {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ${MobileMediaQuery} {
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  ${DesktopMediaQuery} {
    position: absolute;
    right: 0;
  }

  ${MobileMediaQuery} {
    width: 100%;
    margin-top: 1rem;
  }
`

const ProductAssistantBrowserSVG = styled(ProductAssistantBrowser)`
  height: 668px;
  ${MobileMediaQuery} {
    width: 95%;
    height: initial;
  }
`

const ProductAssistantSVG = styled(ProductAssistant)`
  position: absolute;
  width: 351px;
  height: 539px;
  right: 40px;
  top: 90px;

  ${MobileMediaQuery} {
    width: 55%;
    right: 12%;
    top: 11%;
    height: initial;
  }
`

const NewWrapper = styled.span`
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: 1000px;
  padding: 7px 14px;
  font-size: 15px;
  margin-right: 6px;
  height: 37px;
  ${MobileMediaQuery} {
    height: 33px;
  }
`

const ArrowListItem = ({ children }) => (
  <ListItemArrow icon={<ThickArrow color={colors.primary} />}>
    {children}
  </ListItemArrow>
)

const CardProductAssistant = () => (
  <Card variant="lightgrey">
    <ImageAbsoluteWrapper>
      <ImageWrapper>
        <ProductAssistantBrowserSVG />
        <ProductAssistantSVG />
      </ImageWrapper>
    </ImageAbsoluteWrapper>
    <ContentWrapper>
      <Title variant="h3" as="h3">
        Seamless product support that gets to work in days, not months.
      </Title>
      <List
        style={{ marginTop: "4rem", fontSize: "20px", color: colors.darkgrey }}
      >
        <ArrowListItem>
          Turn your knowledge & expertise into a working product assistant in
          minutes
        </ArrowListItem>
        <ArrowListItem>
          Automate your repetitive inquiries such as troubleshooting,
          installation, warranty handling and more
        </ArrowListItem>
        <ArrowListItem>
          Effortless escalations with a suite of live support tools
        </ArrowListItem>
        <NewSpan>
          <NewWrapper>NEW</NewWrapper>
          <span>
            Your agents can be more interactive with
            <Link
              to="/product/live-support/"
              style={{
                margin: "0 4px",
                textDecoration: "underline",
                color: colors.primary,
              }}
              onClick={() => {
                trackEvent({
                  category: "view_item",
                  label: "homepage product assistant list live video",
                  siteSection: "homepage_product_assistant",
                })
              }}
            >
              live video
            </Link>
            support
          </span>
        </NewSpan>
      </List>
      <Button as={Link} to="/request-demo/">
        Request a demo
      </Button>
    </ContentWrapper>
  </Card>
)

export default CardProductAssistant
