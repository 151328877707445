import React from "react"
import colors from "../../styles/colors"
import Card, { CardTitle } from "../card"
import CardPartners from "../cards/cardPartners"

import Layout from "../layout"
import SEO from "../seo"
import Typography from "../typography"
import { CardBook } from "./cardBook"
import { CardMathijs } from "./cardMathijs"
import { TestDriveTiles } from "./testDriveTiles"
import { useTestDriveTiles } from "./useTestDriveTiles"

export const TestDrivePageAll = () => {
  const tiles = useTestDriveTiles()
  return (
    <Layout bg="white" newsletter={null} disableCookieBanner>
      <SEO
        title={`Test drive`}
        enableCookiebot={process.env.GATSBY_PAGE_CONFIG === "testdrive"}
      />

      <Card variant="white">
        <CardTitle variant="h1" style={{ color: colors.primary }}>
          Test drive
          <br />
          Mavenoid
        </CardTitle>
        <Typography
          variant="bodyLarge"
          style={{ maxWidth: 550, marginBottom: 40 }}
        >
          Curious to see how Mavenoid can work for your type of products? Select
          your industry and give us a spin.
        </Typography>

        <TestDriveTiles tiles={tiles} style={{ alignSelf: "stretch" }} />
      </Card>

      <CardMathijs />
      <CardPartners
        title={
          <CardTitle as="h3" variant="h3">
            Trusted by leading hardware brands
          </CardTitle>
        }
        variant="white"
        hasCaption={false}
      />
      <CardBook />
    </Layout>
  )
}
