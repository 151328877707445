import React from "react"
import styled from "@emotion/styled"
import colors from "../../../styles/colors"
import { keyframes } from "@emotion/react"
import { MobileMediaQuery } from "../../../styles/constants"
import SelfServiceBox from "../../illustrations/SelfServiceBox"
import LiveSupportBox from "../../illustrations/LiveSupportBox"

const Wrapper = styled.div`
  display: flex;
  height: 443px;
  justify-content: space-around;
  width: 100%;
  position: relative;

  ${MobileMediaQuery} {
    height: 200px;
  }
`

const TicketBarStyles = `
  position: absolute;
  top: 0;
  bottom: 0;

  color: ${colors.white};
  width: 162px;
  text-align: left;
  padding-left: 1rem;
  z-index: 1;

  ${MobileMediaQuery} {
    padding-left: 0.5rem;
    width: 74px;
    font-size: 9px;
  }
`

const SelfService = styled(SelfServiceBox)`
  ${TicketBarStyles}
  left: -40px;
`
const LiveSupport = styled(LiveSupportBox)`
  ${TicketBarStyles}
  right: -40px;
`

const TicketWrapper = styled.div`
  position: absolute;
  display: flex;
  max-width: 1440px;
  width: 100%;
  height: 100%;
`
const TicketsWrapper = styled.div`
  width: 100%;
  position: relative;
`

const OverlayColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to ${p => (p.isReverseBg ? "left" : "right")},
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
`

const CircleKeyframes = keyframes`
from {
  transform: translateX(0%);
}
to {
  transform: translateX(100%);
}
`

const CircleSvg = styled.circle`
  animation: ${CircleKeyframes} ${p => p.speed || 350}ms infinite linear;
  r: 20px;

  ${MobileMediaQuery} {
    r: 5.5px;
  }
`

const Circle = ({ cy, speed, opacity }) => (
  <CircleSvg
    cx="0"
    cy={cy}
    fill={colors.primary}
    speed={speed}
    opacity={opacity}
  />
)

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min) //The maximum is inclusive and the minimum is inclusive
}

const Tickets = ({
  intervalTime,
  numberOfCircles,
  speed,
  circleOpacity = 1,
  children,
  ...props
}) => {
  const [items, setItems] = React.useState([])

  React.useEffect(() => {
    const interval = setInterval(() => {
      const randomYPos = getRandomIntInclusive(15, 85)
      if (items.length < numberOfCircles) {
        setItems([
          ...items,
          <Circle
            cy={`${randomYPos}%`}
            speed={speed}
            opacity={circleOpacity}
          />,
        ])
      } else {
        clearInterval(interval)
      }
    }, intervalTime)

    return () => clearInterval(interval)
  })

  return (
    <TicketsWrapper {...props}>
      {children}
      <svg height="100%" width="100%">
        {items.map((item, i) => (
          <React.Fragment key={i}>{item}</React.Fragment>
        ))}
      </svg>
    </TicketsWrapper>
  )
}

const MiddleTickets = styled(Tickets)`
  max-width: 500px;
`

const CardTicketFlow = ({ ...props }) => (
  <Wrapper {...props}>
    <TicketWrapper>
      <Tickets intervalTime={2000} numberOfCircles={7} speed={1200}>
        <OverlayColor />
      </Tickets>
      <MiddleTickets
        intervalTime={3000}
        numberOfCircles={5}
        speed={1800}
        circleOpacity="0.3"
      >
        <SelfService />
        <LiveSupport />
      </MiddleTickets>
      <Tickets
        intervalTime={3500}
        numberOfCircles={2}
        speed={4200}
        circleOpacity="0.15"
      >
        <OverlayColor isReverseBg />
      </Tickets>
    </TicketWrapper>
  </Wrapper>
)

export default CardTicketFlow
