// @ts-check
import React from "react"

const ZigZagDesktop = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 819 600"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.29 612.091l181.303-172.383-166.102 65.36L.867065 333.715 263.136 61.3628 38.985 1.44497 397.754-482.446l169.495 125.667-173.895 234.542 270.007 72.176-174.893 181.617 219.139-86.2292L818.933 219.957 593.466 434.331l139.405 9.535-14.398 210.508-618.183-42.283z"
    />
  </svg>
)

export default ZigZagDesktop
