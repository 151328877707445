import styled from "@emotion/styled"
import React from "react"
import colors from "../../styles/colors"

import { DesktopMediaQuery, MobileMediaQuery } from "../../styles/constants"
import { ButtonWithArrow } from "../button"
import { CardContentWrapper, CardWrapper } from "../card"
import Typography from "../typography"
import { TestDriveLabel } from "./testDriveLabel"
import { TestDriveTiles } from "./testDriveTiles"
import { useTestDriveTiles } from "./useTestDriveTiles"

const Card = styled(CardWrapper)`
  margin-top: 32px !important;
  ${MobileMediaQuery} {
    height: initial;
    padding-bottom: 0rem;
    overflow: hidden;
  }
  ${DesktopMediaQuery} {
    border-radius: 0;
    max-width: initial;
    margin-left: -4px;
    margin-right: -4px;
  }
`

const Content = styled(CardContentWrapper)`
  flex-direction: column;
  align-items: stretch;
`
const ContentHead = styled.div`
  display: flex;
  flex-direction: column;
`
const BlurbTypography = styled(Typography)`
  ${DesktopMediaQuery} {
    text-align: center;
  }
`

const preferedTestdriveCases = [
  "1vMVmKlRLBxS4BJrSQ9UlG", // Small Home Appliances
  "7nAZZhlGaLV7rg3AvTsZMG", // Large Home Appliances
  "4hSDYIsNZ9VaStyFfJKf6f", // Consumer Electronics
  "7r2xBsBO04o49S5WuYMK09", // Micromobility
  "7dKhzCI4YOt87jcEI3ikjQ", // Multimedia
  "2eOCRdDAXjj8ywhnNBU0ZK", // Power Equipment
]

const TilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -40px;
  margin-right: -40px;

  ${MobileMediaQuery} {
    margin-left: 0;
    margin-right: 0;
  }
`

const Button = styled(ButtonWithArrow)`
  align-self: center;
  &:hover {
    background-color: #666;
    border-color: #666;
    color: ${colors.white};
  }
`

const CardTestDrive = () => {
  const tiles = useTestDriveTiles()
  return (
    <Card variant="darkgrey">
      <Content>
        <ContentHead style={{ alignItems: "center" }}>
          <TestDriveLabel />
          <Typography variant="h2" as="h3" style={{ margin: "20px 0" }}>
            Take Mavenoid for a spin
          </Typography>
          <BlurbTypography variant="bodyLarge">
            Mavenoid is built for hardware support.
            <br />
            Curious to see how it works for your type of products?
            <br />
            Hop into our test drive area and see for yourself.
          </BlurbTypography>
        </ContentHead>
        <TilesContainer style={{ marginTop: 40 }}>
          <Typography variant="h6" style={{ marginBottom: 20 }}>
            Try Mavenoid for your industry
          </Typography>
          <TestDriveTiles
            tiles={tiles
              .slice()
              .sort(
                (th1, th2) =>
                  (preferedTestdriveCases.includes(th1.contentful_id)
                    ? preferedTestdriveCases.indexOf(th1.contentful_id)
                    : Infinity) -
                  (preferedTestdriveCases.includes(th2.contentful_id)
                    ? preferedTestdriveCases.indexOf(th2.contentful_id)
                    : Infinity)
              )
              .slice(0, 6)}
          />
          <Button
            to={"/testdrive"}
            variant="darkgrey"
            style={{ marginTop: "2rem" }}
          >
            Show more product types
          </Button>
        </TilesContainer>
      </Content>
    </Card>
  )
}

export default CardTestDrive
