import React from "react"
import styled from "@emotion/styled"

import { CardWrapper, CardContentWrapper } from "../card"
import Typography from "../typography"
import colors, { hoverColors } from "../../styles/colors"
import { BigPillButton } from "../button"
import { DesktopMediaQuery, MobileMediaQuery } from "../../styles/constants"
import ZigZagMobile from "../illustrations/zigzagMobile"
import ZigZagDesktop from "../illustrations/zigzagDesktop"

const Card = styled(CardWrapper)`
  padding-bottom: 50px;
  height: 600px;

  ${MobileMediaQuery} {
    height: initial;
    padding-bottom: 0rem;
    overflow: hidden;
  }
  ${DesktopMediaQuery} {
    border-radius: 0;
    max-width: initial;
    margin-left: -4px;
    margin-right: -4px;
  }
`

const Content = styled(CardContentWrapper)`
  flex-direction: row;
  height: 100%;
  justify-content: space-around;

  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const Bottom = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  z-index: 1;

  ${MobileMediaQuery} {
    margin-bottom: 2rem;
    width: 100%;
  }
`

const SignUpText = styled(Typography)`
  ${MobileMediaQuery} {
    max-width: 370px;
  }
`

const RequestDemoButton = styled(BigPillButton)`
  height: 90px;
  background-color: ${p =>
    p.variant ? colors[p.variant || "primary"] : colors.primary};
  color: ${colors.white};
  max-width: 330px;
  width: 100%;
  ${MobileMediaQuery} {
    margin-top: 3rem;
  }

  &:hover {
    background-color: ${p => hoverColors[p.variant || "primary"]};
    border-color: ${p => hoverColors[p.variant || "primary"]};
  }
`

const Title = styled(Typography)`
  flex: 3;
  position: relative;
  max-width: 450px;
  ${DesktopMediaQuery} {
    margin-right: 2rem;
    margin-top: 1.3rem;
  }
  ${MobileMediaQuery} {
    margin-top: 60px;
    margin-bottom: 8rem;
  }
`

const ZigZagMobileSvg = styled(ZigZagMobile)`
  position: absolute;
  color: ${p => (p.variant ? colors[p.variant || "primary"] : colors.primary)};

  @media screen and (max-width: 1060px) {
    top: -300px;
  }
  @media screen and (max-width: 780px) {
    top: -200px;
  }
  @media screen and (max-width: 550px) {
    top: -100px;
  }
  @media screen and (max-width: 450px) {
    top: 0;
  }

  ${DesktopMediaQuery} {
    display: none;
  }
`
const ZigZagDesktopSvg = styled(ZigZagDesktop)`
  position: absolute;
  color: ${p => (p.variant ? colors[p.variant || "primary"] : colors.primary)};
  height: 600px;
  top: 0;
  bottom: 0;
  left: -15%;

  ${MobileMediaQuery} {
    display: none;
  }
`

export const CardFullWidth = ({
  title,
  description,
  variant = null,
  trackTryNow,
}) => {
  return (
    <Card variant="darkgrey">
      <ZigZagMobileSvg variant={variant} />
      <Content noMinHeight>
        <ZigZagDesktopSvg variant={variant} />
        <Title variant="h1" as="h3">
          {title}
        </Title>
        <Bottom>
          <SignUpText variant="bodyLarge" style={{ maxWidth: "412px" }}>
            {description}
          </SignUpText>
          <RequestDemoButton
            variant={variant}
            as="a"
            href="/en/request-demo/"
            onClick={trackTryNow}
          >
            Request a demo
          </RequestDemoButton>
        </Bottom>
      </Content>
    </Card>
  )
}
